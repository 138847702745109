import {put} from "redux-saga/effects";
import axios from 'utils/axiosInterceptors';
import * as actions from '../actions'
import ApiBase from '../ApiBase';

export function* uploadFile(action) {
  const url = ApiBase.getUrl() + '/upload';
  const data = action.data

  try {
    const response = yield axios.post(
        url, data,
        {
          headers: {
            'access_token': localStorage.getItem("token")
          }
        })

    yield put(actions.uploadResponse(response.data))
    yield put(actions.getSectorDetail(action.sectorId, {}))
  } catch (error) {
    yield put(actions.uploadResponse(error.response.data))
  }
}


export function* getSectorList(action) {
  const url = ApiBase.getUrl() + '/v2/sectors';
  let params = action.params;
  const limit = params.limit === '~' ? null : 20;
  try {
    yield put(actions.setSectorList({loading: true}, 0))
    const response = yield axios.get(
        url,
        {
          headers: {
            'access_token': localStorage.getItem("token")
          },
          params : {...params, limit}
        })
    const { data, unpublished_content, page } = response.data
    yield put(actions.setSectorList(data, page.total_pages, unpublished_content))
  } catch{

  }
}


export function* getSectorDetail(action) {
  const url = ApiBase.getUrl() + `/v2/sectors/${action.id}`;
  let params = action.params
  try {
    const response = yield axios.get(
        url,
        {
          params: params,
          headers: {
            'access_token': localStorage.getItem("token")
          }
        })
    if ( params.sectorsTopicsTree ) {
      yield put(actions.setSectorsTopicsTree(response.data.data.sectorsTopics))
    } else {
      yield put(actions.setSectorDetail(response.data.data))
    }
    yield put(actions.setSectorsDetailLoading(false));
    // yield put(actions.regframeDetailResponse(null))
  } catch{

  }
}
export function* getTemplate() {
  const url = ApiBase.getUrl() + `/regframe/template`;
  try {
    const response = yield axios.get(
        url,
        {
          headers: {
            'access_token': localStorage.getItem("token")
          }
        })

    let fileUrl = response.data.data.url
    yield put(actions.setTemplate(fileUrl))
  } catch{

  }
}

export function* exportFile(action) {
  const url = ApiBase.getUrl() + `/export`;
  const data = {
    sector_uuid: action.sectorId,
    filename: "export"
  }

  try {
    yield put(actions.setLoader(true));
    const response = yield axios.post(
        url, data, {
          headers: {
            'access_token': localStorage.getItem("token"),
          },
          responseType: 'blob',
          params: action.filter
        })


    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    yield put(actions.setLoader(false));
    yield file.click()

  } catch (error) {
    console.log("ERROR", error)
    // yield put(actions.uploadResponse(error.response.data))
  }

}

export function* publishDynamicContent(action) {
  const url = ApiBase.getUrl() + '/sectors/dynamic-content-publish';
  let params = action.params

  try {
    yield put(actions.setLoader(true))
    const response = yield axios.post(
        url, params,
        {
          headers: {
            'access_token': localStorage.getItem("token")
          },
          responseType: 'blob'
        })
    
    if (response) yield put(actions.setLoader(false))
  } catch (error) {
    yield put(actions.setLoader(false))
  }
}

export function* publishCompareResultContent(action) {
  const url = ApiBase.getUrl() + '/sectors/dynamic-content-publish-result-compare';
  let params = action.params

  try {
    yield put(actions.setLoader(true))
    const response = yield axios.post(
        url, params,
        {
          headers: {
            'access_token': localStorage.getItem("token")
          }
        })

    if(response){
      yield put(actions.setLoader(false))
    }

  } catch (error) {
    yield put(actions.setLoader(false))
  }
}

export function* syncDynamicContentData(action) {
  const url = ApiBase.getUrl() + '/sectors/sync-dynamic-content-data';
  let params = action.params

  try {
    yield axios.post(
        url, params,
        {
          headers: {
            'access_token': localStorage.getItem("token")
          }
        })
    action.onSuccess();
  } catch{

  }
}

export function* downloadCompareData(action){
  const { filePath, setPublishProgress } = action.payload;

  try{
    const response = yield axios.get(filePath, {
      headers: {
        'access_token': localStorage.getItem("token"),
      },
      responseType: 'blob',
      onDownloadProgress: (e) => {
        setPublishProgress(Math.round(e.loaded * 100 / e.total))
      },
    })

    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const splittedPath = filePath.split('/')
    const fileName = splittedPath[splittedPath.length - 1];

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download", fileName);

    yield file.click()
    
    action.payload.setDownloadCompareFile(null);
    action.payload.setLoading(false);
    yield put(actions.setLoader(false))
  }catch(err){
    console.log('error', err);
  }
}

export function* downloadObligationUpdateData(action) {
  yield put(actions.setLoading(true));
  try{
    const response = yield axios.get(`${ApiBase.getUrl()}/obligations/download-updates`, {
      headers: {
        'access_token': localStorage.getItem("token"),
      },
      params: action.payload,
      responseType: 'blob',
    })
    const blob = yield new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
    });

    const file = yield document.createElement("a");
    let dataFile = yield URL.createObjectURL(blob);
    file.setAttribute("href", dataFile);
    file.setAttribute("download",
        response.headers['content-disposition'] &&
        response.headers['content-disposition'].split('filename=')[1].replace(/["']/g, '')
    );
    file.click()
  }catch(err){
    console.log('error', err);
  } finally {
    yield put(actions.setLoading(false));
  }
}