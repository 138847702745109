export const uploadFile = (data, sectorId) => {
  return {
    type: "UPLOAD_XLS_FILE",
    data,
    sectorId
  }
}

export const exportFile = (sectorId,filter) => {
  return {
    type: "GET_EXPORT_FILE",
    sectorId,
    filter
  }
}

export const uploadResponse = (payload) => {
  return {
    type: "SET_UPLOAD_RESPONSE",
    payload
  }
}

export const deleteUploadResponse = () => {
  return {
    type: "DELETE_UPLOAD_RESPONSE",
  }
}



export const getSectorList = (params) => {
  return {
    type: "GET_SECTOR_LIST",
    params
  }
}

export const setSectorsDetailLoading = (payload) => {
  return {
    type: "SET_SECTORS_DETAIL_LOADING",
    payload,
  }
}


export const setSectorList = (sectors, pageTotal, unpublishedContent, last_sync_clear_cache) => {
  return {
    type: "SET_SECTOR_LIST",
    sectors,
    pageTotal,
    unpublishedContent,
    last_sync_clear_cache,
  }
}

export const getSectorDetail = (id, params) => {
  return {
    type: "GET_SECTOR_DETAIL",
    id,
    params
  }
}


export const setSectorDetail = (sector) => {
  return {
    type: "SET_SECTOR_DETAIL",
    sector
  }
}

export const setSectorsTopicsTree = (data) => {
  return {
    type: "SET_SECTORS_TOPICS_TREE",
    data
  }
}

export const getTemplate = () => {
  return {
    type: "GET_XLS_TEMPLATE",
  }
}

export const setTemplate = (fileUrl) => {
  return {
    type: "SET_XLS_TEMPLATE",
    fileUrl
  }
}

export const publishDynamicContent = (params) => {
  return {
    type: "PUBLISH_DYNAMIC_CONTENT",
    params
  }
}

export const publishCompareResultContent = (params) => {
  return {
    type: "PUBLISH_COMPARE_RESULT_CONTENT",
    params
  }
}

export const syncDynamicContentData = (params, onSuccess) => {
  return {
    type: "SYNC_DYNAMIC_CONTENT_DATA",
    params,
    onSuccess,
  }
}

export const downloadCompareData = (payload) => {
  return {
    type: "DOWNLOAD_COMPARE_DATA",
    payload
  }
}

export const downloadObligationUpdateData = (payload) => {
  return {
    type: "DOWNLOAD_OBLIGATION_UPDATE_DATA",
    payload
  }
}
