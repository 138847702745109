import { updateState } from '../../utils/updateState.js';

const initialState = {
  response: null,
  responseType: null,
  filterObligation: null,
  filterTopics: null,
  moveDestinations: null,
  showMoveItemV3: false,
  loadingMoveItemV3: false,
  errorMessageMoveItemv3: '',
  loading: false,
  sortedItem: null,
  sortedItemType: null,
  downloadContentType: null,
  topicsCardWitdh: 0,
};

const deleteTopicResponse = (state) => {
  return updateState(state, { response: null });
};

const setResponse = (state, action) => {
  return updateState(state, {
    response: action.payload,
    responseType: action.actionType,
  });
};

const setFilterObligation = (state, action) => {
  return updateState(state, {
    filterObligation: action.payload
  })
}

const setFilterTopics = (state, action) => {
  return updateState(state, {
    filterTopics: action.payload
  })
}

const setMoveDestinations = (state, action) => {
  let newMoveDestinations = action.payload;
  if (action.updateType === 'sectors') {
    newMoveDestinations = [action.payload];
  } else if (action.updateType === 'topics') {
    newMoveDestinations = [ ...state.moveDestinations, action.payload ];
  } else if (action.updateType === 'pop') {
    newMoveDestinations = state.moveDestinations.slice(0, -1);
  } else if (action.updateType === 'empty') {
    newMoveDestinations = [ ...state.moveDestinations, [] ];
  } else if (action.updateType === 'sector-only') {
    newMoveDestinations = state.moveDestinations.slice(0, 1);
  }
  return updateState(state, {
    moveDestinations: newMoveDestinations,
  })
}

const setShowMoveItemV3 = (state, action) => {
  return updateState(state, {
    showMoveItemV3: action.payload
  })
}

const setLoadingMoveItemV3 = (state, action) => {
  return updateState(state, {
    loadingMoveItemV3: action.payload
  })
}

const setErrorMessageMoveItemv3 = (state, action) => {
  return updateState(state, {
    errorMessageMoveItemv3: action.payload
  })
}

const setLoading = (state, action) => {
  return updateState(state, {
    loading: action.payload
  })
}

const setSortedItem = (state, action) => {
  return updateState(state, {
    sortedItem: action.payload
  })
}

const setSortedItemType = (state, action) => {
  return updateState(state, {
    sortedItemType: action.payload
  })
}

const setDownloadContentType = (state, action) => {
  return updateState(state, {
    downloadContentType: action.payload
  })
}

const setTopicsCardWitdh = (state, action) => {
  return updateState(state, {
    topicsCardWitdh: action.payload
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'OBLIGATION_DELETE_TOPIC_RESPONSE': return deleteTopicResponse(state);
    case 'REGFRAME_DETAIL_RESPONSE': return setResponse(state, action);
    case 'SET_FILTER_OBLIGATION': return setFilterObligation(state, action);
    case 'SET_FILTER_TOPICS': return setFilterTopics(state, action);
    case 'SET_MOVE_DESTINATIONS': return setMoveDestinations(state, action);
    case 'SET_SHOW_MOVE_ITEM_V3': return setShowMoveItemV3(state, action);
    case 'SET_LOADING_MOVE_ITEM_V3': return setLoadingMoveItemV3(state, action);
    case 'SET_ERROR_MESSAGE_MOVE_ITEM_V3': return setErrorMessageMoveItemv3(state, action);
    case 'SET_LOADING': return setLoading(state, action);
    case 'SET_SORTED_ITEM': return setSortedItem(state, action);
    case 'SET_SORTED_ITEM_TYPE': return setSortedItemType(state, action);
    case 'SET_DOWNLOAD_CONTENT_TYPE': return setDownloadContentType(state, action);
    case 'SET_TOPICS_CARD_WIDTH': return setTopicsCardWitdh(state, action);
    default:
      return state;
  }
};

export default reducer;
