export const publishObligation = (data, sectorId, versionCode) => {
  return {
    type: 'OBLIGATION_PUBLISH_DRAFT',
    data,
    sectorId,
    versionCode,
    status: 1
  };
};

export const publishObligationMaster = (data, sectorId, versionCode) => {
  return {
    type: 'OBLIGATION_PUBLISH_DRAFT',
    data,
    sectorId,
    versionCode,
    status: 1,
    is_master: true,
  };
};

export const deleteTopicResponse = () => {
  return {
    type: 'OBLIGATION_DELETE_TOPIC_RESPONSE',
  };
}

export const regframeDetailResponse = (payload, actionType) => {
  return {
    type: 'REGFRAME_DETAIL_RESPONSE',
    payload,
    actionType
  };
}

export const draftObligation = (data, sectorId) => {
  return {
    type: 'OBLIGATION_PUBLISH_DRAFT',
    data,
    sectorId,
    status: 0
  };
};

export const deleteObligation = (topic, obligation, sectorId) => {
  return {
    type: 'OBLIGATION_DELETE',
    topic,
    obligation,
    sectorId
  };
};

export const deleteTopic = (id, sectorId, customerId) => {
  return {
    type: 'TOPIC_DELETE',
    id, sectorId, customerId
  };
};

export const setDeleteTopicResponse = payload => {
  return {
    type: 'SET_DELETE_TOPIC_RESPONSE',
    payload
  };
};

export const createTopic = data => {
  return {
    type: 'CREATE_TOPIC',
    data
  };
};

export const updateTopic = data => {
  return {
    type: 'UPDATE_TOPIC',
    data
  };
};

export const activateObligation = (data, sectorId) => {
  return {
    type: 'BULK_ACTIVATE_OBLIGATION',
    data,
    sectorId
  };
};

export const getFilterObligation = (params) => {
  return {
    type: "GET_FILTER_OBLIGATION",
    params
  }
}

export const setFilterObligation = (payload) => {
  return {
    type: "SET_FILTER_OBLIGATION",
    payload
  }
}

export const getFilterTopics = (params) => {
  return {
    type: "GET_FILTER_TOPICS",
    params
  }
}

export const setFilterTopics = (payload) => {
  return {
    type: "SET_FILTER_TOPICS",
    payload
  }
}

export const downloadTemplate = (payload) => {
  return {
    type: "DOWNLOAD_TEMPLATE",
    payload
  }
}

export const getSectorsMoveDestinations = (payload) => {
  return {
    type: "GET_SECTORS_MOVE_DESTINATIONS",
    payload
  }
}

export const getTopicsMoveDestinations = (payload) => {
  return {
    type: "GET_TOPICS_MOVE_DESTINATIONS",
    payload
  }
}

export const setMoveDestinations = (payload, updateType) => {
  return {
    type: "SET_MOVE_DESTINATIONS",
    payload,
    updateType,
  }
}

export const setShowMoveItemV3 = (payload, updateType) => {
  return {
    type: "SET_SHOW_MOVE_ITEM_V3",
    payload,
    updateType,
  }
}

export const setLoadingMoveItemv3 = (payload) => {
  return {
    type: "SET_LOADING_MOVE_ITEM_V3",
    payload
  }
}

export const setErrorMessageMoveItemv3 = (payload) => {
  return {
    type: "SET_ERROR_MESSAGE_MOVE_ITEM_V3",
    payload
  }
}

export const moveItemv3Submit = (payload, onSuccess) => {
  return {
    type: "MOVE_ITEM_V3_SUBMIT",
    payload,
    onSuccess,
  }
}

export const setLoading = (payload) => {
  return {
    type: "SET_LOADING",
    payload
  }
}

export const setSortedItem = (payload) => {
  return {
    type: "SET_SORTED_ITEM",
    payload
  }
}

export const setSortedItemType = (payload) => {
  return {
    type: "SET_SORTED_ITEM_TYPE",
    payload
  }
}

export const submitSortTopics = (payload, sectorId) => {
  return {
    type: "SUBMIT_SORT_TOPICS",
    payload,
    sectorId
  }
}

export const submitSortObligations = (payload, sectorId) => {
  return {
    type: "SUBMIT_SORT_OBLIGATIONS",
    payload,
    sectorId
  }
}

export const initiateDownloadContentRegframe = (data) => {
  return {
    type: "INITIATE_DOWNLOAD_CONTENT_REGFRAME",
    data
  }
}

export const downloadObligationCompanies = (data) => {
  return {
    type: "DOWNLOAD_OBLIGATION_COMPANIES",
    data
  }
}

export const downloadObligationRegulations = (data) => {
  return {
    type: "DOWNLOAD_OBLIGATION_REGULATIONS",
    data
  }
}

export const downloadContentRegframe = (payload) => {
  return {
    type: "DOWNLOAD_CONTENT_REGFRAME",
    payload
  }
}

export const setDownloadContentType = (payload) => {
  return {
    type: "SET_DOWNLOAD_CONTENT_TYPE",
    payload
  }
}

export const setTopicsCardWitdh = (payload) => {
  return {
    type: "SET_TOPICS_CARD_WIDTH",
    payload
  }
}
