import { all, takeEvery, takeLatest } from "redux-saga/effects";

import {
  checkAuthSaga,
  getUserDataSaga,
  getUserProfile,
  loginSaga,
  logout,
  updateDataUser,
  getUserDetail,
  getListRole,
  getDetailRole,
  updateUserById,
  deleteUserById,
  updateLicenseSubscription,
  userCreatePassword,
  checkInviteUserToken,
} from "./user";

import {
  exportFile,
  getSectorDetail,
  getSectorList,
  getTemplate,
  uploadFile,
  publishDynamicContent,
  publishCompareResultContent,
  syncDynamicContentData,
  downloadCompareData,
  downloadObligationUpdateData,
} from "./regulatoryFramework";

import {
  createSector,
  deleteSector,
  updateSector
} from "./regulatory-framework/sector";

import {
  addRegulation,
  bookmarkRegulation,
  getRegulationCentral,
  getRegulationDetail,
  getRegulationList,
  searchRegulation,
  syncRegulaiton,
  uploadDocumentRegulation,
  deleteDocumentRegulation,
  downloadDocumentRegulation,
  downloadRegulationObligations,
  downloadRegulationCompanies,
} from "./regulatory-framework/regulation";

import { reqNotif, getCountStaticNotif, getDetailNotif } from "./notification";

import {
  downloadAlertTemplate,
  getAlertList,
  syncAlert,
  updateAlertList
} from "./settingNotification";

import {
  bookmarkObligation,
  createObligation,
  getObligationDetail,
  getObligationType,
  saveObligation,
  getObligationList,
  getListUpdateObligation,
  getDetailUpdateObligation,
  applyObligationChanges,
  getListUpdateObligationByRegulation,
  getObligationUpdatingContentList
} from "./regulatory-framework/obligation";

import { listLanguage, requestLanguage } from "./settings/language";

import {
  createTopic,
  deleteObligation,
  deleteTopic,
  publishDraftObligation,
  updateTopic,
  activateObligation,
  getFilterObligation,
  getFilterTopics,
  downloadTemplate,
  getSectorsMoveDestinations,
  getTopicsMoveDestinations,
  moveItemv3Submit,
  submitSortTopics,
  submitSortObligations,
  initiateDownloadContentRegframe,
  downloadObligationCompanies,
  downloadObligationRegulations,
  downloadContentRegframe,
} from "./regframeDetail";

import { recoveryPassword, submitPasswordRecovery } from "./auth/auth";

import {
  deleteBookmarkObligation,
  deleteBookmarkRegulation,
  getBookmarkObligation,
  getBookmarkRegulation,
  getBookmarkDocument,
  deleteBookmarkDocument,
  getBookmarkCompliance,
  deleteBookmarkCompliance
} from "./bookmark";

import {
  userGetUserList,
  getUserListCompany,
  getGroupUserCompany,
  userGetUserGroupList,
  userCreateUser,
  userGetRolePrivilege,
  userAddRolePrivilege,
  userGetModulePrivilege,
  userDeleteRolePrivilege,
  userUpdateRolePrivilege,
  getGroupUserList,
  createGroupUser,
  deleteGroupUser,
  syncLDAP,
  getGroupUserDetail,
  updateGroupUser,
  getListUserCompany,
  searchUserCompany,
  getGroupLabel,
  addGroupLabel,
  userInviteUser,
  userGetUserListNonGroup,
} from "./userManagement";

import {
  customerCreateCustomer,
  customerGetCustomerList,
  customerUpdateCustomer,
  customerDeleteCustomer,
  getCustomerDetail,
  getCustomerActivity,
  // generateCsv,
  uploadCsv,
  uploadInitData,
  updateCompanyProgress
} from "./customerManagement";

import { getSearchResult, getSearchSuggestions, getSearchElastic } from "./search";

import {
  getRegulationHierarchy,
  addRegulationHierarchy,
  updateRegulationHierarchy,
  deleteRegulationHierarchy,
  syncRegulationHierarchy,
} from "./regulationHierarchy";

import { getPreferences, updatePreferences, getLoginPreferences } from "./settings/preferences";

import {
  getDataDashboard,
  getDashboardComplianceStatus,
  getDashboardComplianceStatistics,
  getReportDashboard,
  getDashboardGroup,
  searchDashboardGroup
} from "./dashboard/dashboard";

import { getProductList } from "./product/product";

import { restore, getBackupList } from "./settings/restoreBackup";

import {
  getListCompliance,
  getListComplianceDashboard,
  getDetailCompliance,
  assignPICtoCompliance,
  bulkAssignToCompliance,
  updateDetailCompliance,
  getListRoleCompliance,
  addRoleCompliance,
  deleteRoleCompliance,
  getListObligationAction,
  getDetailRoleCompliance,
  updateDetailRoleCompliance,
  getListMemberCompliance,
  updateDetailMemberCompliance,
  addMemberCompliance,
  updateProcessCompliance,
  updateStatusCompliance,
  createChecklistCompliance,
  updateChecklistCompliance,
  deleteChecklistCompliance,
  getDetailChecklistCompliance,
  bookmarkCompliance,
  removeMemberCompliance,
  renewalCompliance,
  bulkActivationCompliance,
  comment,
  deleteComment,
  editComment,
  getComment,
  setReminder,
  getActivityList,
  getActivityListAll,
  bulkAddMemberCompliance,
  bulkDuplicateCompliance,
  getFilterCompliance,
  bulkDeleteCompliance,
  confirmCompliance,
  getComplianceDetailChanges,
  getComplianceMainInfoChanges,
  getComplianceChecklistChanges,
  getComplianceRegulationChanges,
} from './compliance-monitor/compliance';

import {
  getFoldersList,
  getChecklistList,
  getDocumentList,
  bookmarkDocument,
  uploadDocumentRequest,
  downloadDocument,
  updateDocument,
  removeDocument,
  getComplianceFolder,
  getDetailDocument,
  relationDocument,
  relationRemove,
  searchDocumentRelated
} from "./documentManagement";

import {
  getListStaticPage,
  getDetailStaticPage,
  createStaticPage,
  updateStaticPage,
  deleteStaticPage
} from "./StaticPages/StaticPages";

import {
  getVersion
} from "./aboutApp";

import {
  getLoader
} from "./loaderApp";

import {
  sendFeedback,
} from './feedbackForm'

export function* watchUserManagement() {
  yield all([
    takeEvery("USER_MANAGEMENT_GET_USER_LIST", userGetUserList),
    takeEvery("GET_USER_LIST_COMPANY", getUserListCompany),
    takeEvery("GET_GROUP_USER_COMPANY", getGroupUserCompany),
    takeEvery("USER_MANAGEMENT_GET_USER_GROUP_LIST", userGetUserGroupList),
    takeEvery("USER_MANAGEMENT_CREATE_USER", userCreateUser),
    takeEvery("USER_MANAGEMENT_GET_ROLE_PRIVILEGE", userGetRolePrivilege),
    takeEvery("USER_MANAGEMENT_ADD_ROLE_PRIVILEGE", userAddRolePrivilege),
    takeEvery("USER_MANAGEMENT_GET_MODULE_PRIVILEGE", userGetModulePrivilege),
    takeEvery("USER_MANAGEMENT_DELETE_ROLE_PRIVILEGE", userDeleteRolePrivilege),
    takeEvery("USER_MANAGEMENT_UPDATE_ROLE_PRIVILEGE", userUpdateRolePrivilege),
    takeEvery("GET_GROUP_USER_LIST", getGroupUserList),
    takeEvery("GET_GROUP_USER_DETAIL", getGroupUserDetail),
    takeEvery("CREATE_GROUP_USER", createGroupUser),
    takeEvery("DELETE_GROUP_USER", deleteGroupUser),
    takeEvery("SYNC_LDAP", syncLDAP),
    takeEvery("UPDATE_GROUP_USER", updateGroupUser),
    takeEvery("GET_LIST_USER_COMPANY", getListUserCompany),
    takeEvery("SEARCH_USER_COMPANY", searchUserCompany),
    takeEvery("ADD_GROUP_LABEL", addGroupLabel),
    takeEvery("GET_GROUP_LABEL", getGroupLabel),
    takeEvery("USER_INVITE_USER", userInviteUser),
    takeEvery("USER_MANAGEMENT_GET_USER_LIST_NON_GROUP", userGetUserListNonGroup),
  ]);
}

export function* watchCustomerManagement() {
  yield all([
    takeEvery("CUSTOMER_MANAGEMENT_GET_CUSTOMER_LIST", customerGetCustomerList),
    takeEvery("CUSTOMER_MANAGEMENT_CREATE_CUSTOMER", customerCreateCustomer),
    takeEvery("CUSTOMER_MANAGEMENT_UPDATE_CUSTOMER", customerUpdateCustomer),
    takeEvery("CUSTOMER_MANAGEMENT_DELETE_CUSTOMER", customerDeleteCustomer),
    takeEvery("GET_CUSTOMER_DETAIL", getCustomerDetail),
    takeEvery("GET_CUSTOMER_ACTIVITY", getCustomerActivity),
    // takeEvery("GENERATE_CSV", generateCsv),
    takeEvery("UPLOAD_CSV", uploadCsv),
    takeEvery("UPLOAD_INIT_DATA", uploadInitData),
    takeEvery("UPDATE_COMPANY_PROGRESS", updateCompanyProgress)
  ]);
}

export function* watchRegulationHierarchy() {
  yield all([
    takeEvery("GET_REGULATION_HIERARCHY", getRegulationHierarchy),
    takeEvery("ADD_REGULATION_HIERARCHY", addRegulationHierarchy),
    takeEvery("UPDATE_REGULATION_HIERARCHY", updateRegulationHierarchy),
    takeEvery("DELETE_REGULATION_HIERARCHY", deleteRegulationHierarchy),
    takeEvery("SYNC_REGULATION_HIERARCHY", syncRegulationHierarchy),
  ]);
}

export function* watchUser() {
  yield all([
    takeEvery("USER_LOGIN", loginSaga),
    takeEvery("USER_CHECK_AUTH", checkAuthSaga),
    takeEvery("USER_LOGOUT", logout),
    takeEvery("USER_GET_DATA", getUserDataSaga),
    takeEvery("USER_UPDATE_DATA", updateDataUser),
    takeEvery("RECOVERY_PASSWORD", recoveryPassword),
    takeEvery("SUBMIT_PASSWORD_RECOVERY", submitPasswordRecovery),
    takeEvery("GET_USER_PROFILE", getUserProfile),
    takeEvery("GET_USER_DETAIL", getUserDetail),
    takeEvery("USER_UPDATE_BY_ID", updateUserById),
    takeEvery("DELETE_USER_ID", deleteUserById),
    takeEvery("UPDATE_LICENSE_SUBSCRIPTION", updateLicenseSubscription),
    // role
    takeEvery("GET_LIST_ROLE", getListRole),
    takeEvery("GET_DETAIL_ROLE", getDetailRole),
    takeEvery("USER_CREATE_PASSWORD", userCreatePassword),
    takeEvery("CHECK_INVITE_USER_TOKEN", checkInviteUserToken)
  ]);
}

export function* watchRegFrame() {
  yield all([
    takeEvery("UPLOAD_XLS_FILE", uploadFile),
    takeEvery("GET_EXPORT_FILE", exportFile),
    takeEvery("GET_SECTOR_LIST", getSectorList),
    takeEvery("GET_SECTOR_DETAIL", getSectorDetail),
    takeEvery("GET_XLS_TEMPLATE", getTemplate),
    takeEvery("PUBLISH_DYNAMIC_CONTENT", publishDynamicContent),
    takeEvery("PUBLISH_COMPARE_RESULT_CONTENT", publishCompareResultContent),
    takeEvery("SYNC_DYNAMIC_CONTENT_DATA", syncDynamicContentData),
    takeEvery("DOWNLOAD_COMPARE_DATA", downloadCompareData),
    takeEvery("DOWNLOAD_OBLIGATION_UPDATE_DATA", downloadObligationUpdateData),

    // sector
    takeEvery("CREATE_SECTOR", createSector),
    takeEvery("UPDATE_SECTOR", updateSector),
    takeEvery("DELETE_SECTOR", deleteSector),

    // regulation
    takeEvery("ADD_REGULATION", addRegulation),
    takeEvery("SEARCH_REGULATION", searchRegulation),
    takeEvery("GET_REGULATION_CENTRAL", getRegulationCentral),
    takeEvery("GET_REGULATION_DETAIL", getRegulationDetail),
    takeEvery("GET_BOOKMARK_REGULATION", getBookmarkRegulation),
    takeEvery("BOOKMARK_REGULATION", bookmarkRegulation),
    takeEvery("GET_REGULATION_LIST", getRegulationList),
    takeEvery("SYNC_REGULATION", syncRegulaiton),
    takeEvery("UPLOAD_DOCUMENT_REGULATION", uploadDocumentRegulation),
    takeEvery('DELETE_DOCUMENT_REGULATION', deleteDocumentRegulation),
    takeEvery('DOWNLOAD_DOCUMENT_REGULATION', downloadDocumentRegulation),
    takeEvery('DOWNLOAD_REGULATION_OBLIGATIONS', downloadRegulationObligations),
    takeEvery('DOWNLOAD_REGULATION_COMPANIES', downloadRegulationCompanies),

    // obligation
    takeEvery("GET_OBLIGATION_LIST", getObligationList),
    takeEvery("GET_OBLIGATION_DETAIL", getObligationDetail),
    takeEvery("SAVE_OBLIGATION_DETAIL", saveObligation),
    takeEvery("GET_OBLIGATION_TYPE", getObligationType),
    takeEvery("BOOKMARK_OBLIGATION", bookmarkObligation),
    takeEvery("GET_BOOKMARK_OBLIGATION", getBookmarkObligation),
    takeEvery("CREATE_OBLIGATION", createObligation),
    takeEvery("GET_LIST_UPDATE_OBLIGATION", getListUpdateObligation),
    takeEvery("GET_LIST_UPDATE_OBLIGATION_BY_REGULATION", getListUpdateObligationByRegulation),
    takeEvery("GET_DETAIL_UPDATE_OBLIGATION", getDetailUpdateObligation),
    takeEvery("APPLY_OBLIGATION_CHANGES", applyObligationChanges),
    takeEvery("GET_OBLIGATION_UPDATING_CONTENT_LIST", getObligationUpdatingContentList),
  ]);
}

export function* watchRegFrameDetail() {
  yield all([
    takeEvery("OBLIGATION_PUBLISH_DRAFT", publishDraftObligation),
    takeEvery("OBLIGATION_DELETE", deleteObligation),
    takeEvery("TOPIC_DELETE", deleteTopic),
    takeEvery("CREATE_TOPIC", createTopic),
    takeEvery("UPDATE_TOPIC", updateTopic),
    takeEvery("BULK_ACTIVATE_OBLIGATION", activateObligation),
    takeEvery("GET_FILTER_OBLIGATION", getFilterObligation),
    takeEvery("GET_FILTER_TOPICS", getFilterTopics),
    takeEvery("DOWNLOAD_TEMPLATE", downloadTemplate),
    takeEvery("GET_SECTORS_MOVE_DESTINATIONS", getSectorsMoveDestinations),
    takeEvery("GET_TOPICS_MOVE_DESTINATIONS", getTopicsMoveDestinations),
    takeEvery("MOVE_ITEM_V3_SUBMIT", moveItemv3Submit),
    takeEvery("SUBMIT_SORT_TOPICS", submitSortTopics),
    takeEvery("SUBMIT_SORT_OBLIGATIONS", submitSortObligations),
    takeEvery("INITIATE_DOWNLOAD_CONTENT_REGFRAME", initiateDownloadContentRegframe),
    takeEvery("DOWNLOAD_OBLIGATION_COMPANIES", downloadObligationCompanies),
    takeEvery("DOWNLOAD_OBLIGATION_REGULATIONS", downloadObligationRegulations),
    takeEvery("DOWNLOAD_CONTENT_REGFRAME", downloadContentRegframe),
  ]);
}

export function* notification() {
  yield all([
    takeEvery("GET_NOTIFICATION", reqNotif),
    takeEvery("GET_COUNTER_STATIC_NOTIFICATION", getCountStaticNotif),
    takeEvery("GET_DETAIL_NOTIFICATION", getDetailNotif)
  ]);
}

export function* settingNotification() {
  yield all([
    takeEvery("GET_ALERT_LIST", getAlertList),
    takeEvery("UPDATE_ALERT_LIST", updateAlertList),
    takeEvery("SYNC_ALERT", syncAlert),
    takeEvery("DOWNLOAD_ALERT_TEMPLATE", downloadAlertTemplate)
  ]);
}

export function* settings() {
  yield all([
    takeEvery("REQUEST_LANGUAGE", requestLanguage),
    takeEvery("LIST_LANGUAGE", listLanguage)
  ]);
}

export function* watchBookmark() {
  yield all([
    takeEvery("GET_BOOKMARK_OBLIGATION", getBookmarkObligation),
    takeEvery("GET_BOOKMARK_REGULATION", getBookmarkRegulation),
    takeEvery("GET_BOOKMARK_DOCUMENT", getBookmarkDocument),
    takeEvery("GET_BOOKMARK_COMPLIANCE", getBookmarkCompliance),
    takeEvery("DELETE_BOOKMARK_OBLIGATION", deleteBookmarkObligation),
    takeEvery("DELETE_BOOKMARK_REGULATION", deleteBookmarkRegulation),
    takeEvery("DELETE_BOOKMARK_DOCUMENT", deleteBookmarkDocument),
    takeEvery("DELETE_BOOKMARK_COMPLIANCE", deleteBookmarkCompliance)
  ]);
}

export function* watchSearch() {
  yield all([
    takeLatest("GET_SEARCH_SUGGESTION", getSearchSuggestions),
    takeEvery("GET_SEARCH_RESULT", getSearchResult),
    takeEvery("GET_SEARCH_ELASTIC", getSearchElastic)
  ]);
}

export function* watchPreferences() {
  yield all([
    takeEvery("GET_PREFERENCES", getPreferences),
    takeEvery("UPDATE_PREFERENCES", updatePreferences),
    takeEvery("GET_LOGIN_PREFERENCES", getLoginPreferences),
  ]);
}

export function* watchDashboard() {
  yield all([
    takeEvery("GET_DATA_DASHBOARD", getDataDashboard),
    takeEvery("GET_DASHBOARD_COMPLIANCE_STATUS", getDashboardComplianceStatus),
    takeEvery(
        "GET_DASHBOARD_COMPLIANCE_STATISTICS",
        getDashboardComplianceStatistics
    ),
    takeEvery("GET_REPORT_DASHBOARD", getReportDashboard),
    takeEvery("GET_DASHBOARD_GROUP", getDashboardGroup),
    takeEvery("SEARCH_DASHBOARD_GROUP", searchDashboardGroup)
  ]);
}

export function* watchProduct() {
  yield all([takeEvery("GET_PRODUCT_LIST", getProductList)]);
}

export function* watchRestore() {
  yield all([
    takeEvery("RESTORE_DATA", restore),
    takeEvery("GET_BACKUP_LIST", getBackupList)
  ]);
}

export function* watchCompliance() {
  yield all([
    takeEvery("GET_LIST_COMPLIANCE", getListCompliance),
    takeEvery("GET_LIST_COMPLIANCE_DASHBOARD", getListComplianceDashboard),
    takeEvery("GET_DETAIL_COMPLIANCE", getDetailCompliance),
    takeEvery("ASSIGN_PIC_TO_COMPLIANCE", assignPICtoCompliance),
    takeEvery("BUILK_ASSIGN_TO_COMPLIANCE", bulkAssignToCompliance),
    takeEvery("UPDATE_DETAIL_COMPLIANCE", updateDetailCompliance),
    takeEvery("GET_LIST_ROLE_COMPLIANCE", getListRoleCompliance),
    takeEvery("ADD_ROLE_COMPLIANCE", addRoleCompliance),
    takeEvery("DELETE_ROLE_COMPLIANCE", deleteRoleCompliance),
    takeEvery("GET_LIST_OBLIGATION_ACTION", getListObligationAction),
    takeEvery("GET_DETAIL_ROLE_COMPLIANCE", getDetailRoleCompliance),
    takeEvery("UPDATE_DETAIL_ROLE_COMPLIANCE", updateDetailRoleCompliance),
    takeEvery("ADD_MEMBER_COMPLIANCE", addMemberCompliance),
    takeEvery("UPDATE_DETAIL_MEMBER_COMPLIANCE", updateDetailMemberCompliance),
    takeEvery("GET_LIST_MEMBER_COMPLIANCE", getListMemberCompliance),
    takeEvery("GET_LIST_ROLE_COMPLIANCE", getListRoleCompliance),
    takeEvery("UPDATE_PROCESS_COMPLIANCE", updateProcessCompliance),
    takeEvery("UPDATE_STATUS_COMPLIANCE", updateStatusCompliance),
    takeEvery("CREATE_CHECKLIST_COMPLIANCE", createChecklistCompliance),
    takeEvery("UPDATE_CHECKLIST_COMPLIANCE", updateChecklistCompliance),
    takeEvery("DELETE_CHECKLIST_COMPLIANCE", deleteChecklistCompliance),
    takeEvery("GET_DETAIL_CHECKLIST_COMPLIANCE", getDetailChecklistCompliance),
    takeEvery("BOOKMARK_COMPLIANCE", bookmarkCompliance),
    takeEvery("REMOVE_MEMBER_COMPLIANCE", removeMemberCompliance),
    takeEvery("COMMENT", comment),
    takeEvery("DELETE_COMMENT", deleteComment),
    takeEvery("EDIT_COMMENT", editComment),
    takeEvery("GET_COMMENT", getComment),
    takeEvery("SET_ACTIVITY_LIST", getActivityList),
    takeEvery("GET_ACTIVITY_LIST_ALL", getActivityListAll),
    takeEvery("BULK_ADD_MEMBER_COMPLIANCE", bulkAddMemberCompliance),
    takeEvery("BULK_DUPLICATE_COMPLIANCE", bulkDuplicateCompliance),
    takeEvery("GET_FILTER_COMPLIANCE", getFilterCompliance),
    takeEvery("BULK_DELETE_COMPLIANCE", bulkDeleteCompliance),
    takeEvery("CONFIRM_COMPLIANCE", confirmCompliance),
    takeEvery("GET_COMPLIANCE_DETAIL_CHANGES", getComplianceDetailChanges),
    takeEvery("GET_COMPLIANCE_MAIN_INFO_CHANGES", getComplianceMainInfoChanges),
    takeEvery("GET_COMPLIANCE_CHECKLIST_CHANGES", getComplianceChecklistChanges),
    takeEvery("GET_COMPLIANCE_REGULATION_CHANGES", getComplianceRegulationChanges),
  ]);
}

export function* watchDocumentMangement() {
  yield all([
    takeEvery("GET_FOLDERS_LIST", getFoldersList),
    takeEvery("GET_CHECKLIST_LIST", getChecklistList),
    takeEvery("GET_DOCUMENT_LIST", getDocumentList),
    takeEvery("BOOKMARK_DOCUMENT", bookmarkDocument),
    takeEvery("UPLOAD_DOCUMENT", uploadDocumentRequest),
    takeEvery("DOWNLOAD_DOCUMENT", downloadDocument),
    takeEvery("UPDATE_DOCUMENT", updateDocument),
    takeEvery("REMOVE_DOCUMENT", removeDocument),
    takeEvery("GET_COMPLIANCE_FOLDER", getComplianceFolder),
    takeEvery("GET_DOCUMENT_DETAIL", getDetailDocument),
    takeEvery("RENEWAL_COMPLIANCE", renewalCompliance),
    takeEvery("BULK_ACTIVATION_COMPLIANCE", bulkActivationCompliance),
    takeEvery("SET_REMINDER", setReminder),
    takeEvery("RELATION_DOCUMENT", relationDocument),
    takeEvery("RELATION_REMOVE", relationRemove),
    takeEvery("SEARCH_DOCUMENT_RELATION", searchDocumentRelated)
  ]);
}

export function* watchStaticPage(){
  yield all([
    takeEvery('GET_LIST_STATIC_PAGE', getListStaticPage),
    takeEvery('GET_DETAIL_STATIC_PAGE', getDetailStaticPage),
    takeEvery('CREATE_STATIC_PAGE', createStaticPage),
    takeEvery('UPDATE_STATIC_PAGE', updateStaticPage),
    takeEvery('DELETE_STATIC_PAGE', deleteStaticPage)
  ]);
}

export function* watchAboutApp(){
  yield all([
    takeEvery('GET_VERSION_APP', getVersion)
  ]);
}

export function* watchLoaderApp(){
  yield all([
    takeEvery('GET_LOADER_APP', getLoader)
  ]);
}

export function* feedbackForm() {
  yield all([
    takeEvery('SEND_FEEDBACK', sendFeedback)
  ])
}